@import url("https://pro.fontawesome.com/releases/v6.0.0-beta1/css/all.css");
@import url("https://fonts.googleapis.com/css2?family=Jura:wght@500;600;900&display=swap");

.navbar {
/*    background: linear-gradient(90deg, rgb(74, 41, 219) 0%, rgb(49, 29, 230) 100%); */
/*background: linear-gradient(90deg, rgb(0, 123, 255) 0%, rgb(0, 123, 255) 100%);*/
  background: #fff;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 999;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
  }
  
  .navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    max-width: 1500px;
  }
  
  .navbar-logo {
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .navbar-logo img {
    height: 60px;
    width: auto; 
    margin: 0px auto;
    border-radius: 5px;
  }
  
  .fa-typo3 {
    margin-left: 0.5rem;
    font-size: 1.8rem;
  }
  
  .nav-menu {
    display: flex;
    grid-template-columns: repeat(4, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 60vw;
    justify-content: flex-end;
    margin-right: 2rem;
  }
  
  .nav-item {
    height: 80px;
  }
  
  .nav-links {
    color: #007bff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
  }
  
  .nav-links:hover {
    border-bottom: 4px solid #007bff;
    transition: all 0.2s ease-out;
  }

  .nav-link-btn {
    color: #007bff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
  }
  
  .fa-bars {
    color: #007bff;
  }
  
  .nav-links-mobile {
    display: none;
  }
  
  .menu-icon {
    display: none;
  }
  
  @media screen and (max-width: 960px) {
    .NavbarItems {
      position: relative;
    }
  
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 90vh;
      position: absolute;
      top: 80px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
      justify-content: flex-start;
      padding-left: 0;
    }
  
    .nav-menu.active {
      background: #242222;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
    }
  
    .nav-links {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
    }
  
    .nav-links:hover {
      background-color: #fff;
      color: #007bff;
      border-radius: 0;
    }
  
    .navbar-logo {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(20%, 20%);
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  
    .fa-times {
      color: #007bff;
      font-size: 2rem;
    }
  
    .nav-links-mobile {
      display: block;
      text-align: center;
      margin: 2rem auto;
      border-radius: 4px;
      width: 80%;
      text-decoration: none;
      font-size: 1.5rem;
      background-color: transparent;
      color: #007bff;
      padding: 14px 20px;
      border: 1px solid #fff;
      transition: all 0.3s ease-out;
    }
  
    .nav-links-mobile:hover {
      background: #fff;
      color: #007b;
      transition: 250ms;
    }
  }

  /******************** Navbar Ends *****************************/
  /************** NABL Ribbon *************************/

  /** Ref URL: https://codepen.io/MarkBoots/pen/yLPwvda **/

  .ribbon-box-small {
    display: none;
  }
  
  .ribbon-box {
    position: fixed;
    top: 0;
    left: -20px;
    z-index: 1001;
    display: block;
  }

  ul.ribbon {
    --col-gap: 2rem;
    --barH: 1rem;
    --roleH: 2rem;
    --flapH: 2rem;
  
    width: min(60rem, 90%);
    margin-inline: auto;
  
    display: flex;
    flex-wrap: wrap;
  
    gap: var(--col-gap);
    padding-inline: calc(var(--col-gap) / 2);
  
    justify-content: center;
    align-items: flex-start;
    list-style: none;
  }
  ul.ribbon li {
    width: 10em;
    display: grid;
    grid-template:
      "role"
      "icon"
      "title"
      "descr";
    align-items: flex-start;
    gap: 1rem;
    padding-block-end: calc(var(--flapH) + 1rem);
    text-align: center;
    background: var(--accent-color);
    background-image: linear-gradient(
      rgba(0, 0, 0, 0.6) var(--roleH),
      rgba(0, 0, 0, 0.4) calc(var(--roleH) + 0.5rem),
      rgba(0, 0, 0, 0) calc(var(--roleH) + 0.5rem + 5rem)
    );
    clip-path: polygon(
      calc(var(--col-gap) / -2 - 5px) 0,
      calc(100% + var(--col-gap) / 2 + 5px) 0,
      calc(100% + var(--col-gap) / 2 + 5px ) calc(100% - var(--flapH)),
      50% 100%,
      calc(var(--col-gap) / -2 - 5px) calc(100% - var(--flapH))
    );
  }
  
  /* bar */
  ul.ribbon li::before {
    content: "";
    grid-area: role;
    height: var(--barH);
    width: calc(100% + var(--col-gap));
    margin-left: calc(var(--col-gap) / -2);
    margin-top: calc(var(--roleH) / 2 - var(--barH) / 2);
    /*background: grey;*/
    z-index: -1;
    /*background-image: linear-gradient(
      rgba(255, 255, 255, 0.4),
      rgba(255, 255, 255, 0.2) 30%,
      rgba(255, 255, 255, 0.1) 40%,
      rgba(0, 0, 0, 0.1) 60%,
      rgba(0, 0, 0, 0.2) 70%,
      rgba(0, 0, 0, 0.4)
    );*/
  }
  
  /* role */
  ul.ribbon li::after {
    content: "";
    grid-area: role;
    background: var(--accent-color);
    background-image: linear-gradient(
      rgba(255, 255, 255, 0.4),
      rgba(255, 255, 255, 0.2) 30%,
      rgba(255, 255, 255, 0.1) 40%,
      rgba(0, 0, 0, 0.1) 60%,
      rgba(0, 0, 0, 0.2) 70%,
      rgba(0, 0, 0, 0.4)
    );
    height: var(--roleH);
  }
  
  ul.ribbon li .icon,
  ul.ribbon li .title,
  ul.ribbon li .descr {
    padding-inline: 1rem;
    color: white;
    text-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.5);
  }
  
  ul.ribbon li .icon {
    font-size: 3rem;
  }
  ul.ribbon li .title {
    font-size: 1.25rem;
    font-weight: 700;
  }
  
  ul.ribbon li .descr {
    font-size: 0.9rem;
  }
  
  ul.ribbon li .icon img {
    height: auto;
    width: 80px;
  }

  @media screen and (max-width: 980px) {
    .ribbon-box {
      display: none;
    }

    .ribbon-box-small {
      display: block;
      color: #ffffff;
      padding: 5px;
      content: "";
      grid-area: role;
      background: var(--accent-color);
      background-image: linear-gradient(
        rgba(255, 255, 255, 0.4),
        rgba(255, 255, 255, 0.2) 30%,
        rgba(255, 255, 255, 0.1) 40%,
        rgba(0, 0, 0, 0.1) 60%,
        rgba(0, 0, 0, 0.2) 70%,
        rgba(0, 0, 0, 0.4)
      );
      height: fit-content;
      text-align: center;
      font-size: 18px;
    }
}

  /************** NABL Ribbon Ends *************************/

 