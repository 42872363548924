.service-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #eee;
    padding: 0 1.5rem 10rem;
    margin: 0;
}

.blog-post {
    width: 100%;
    max-width: 80rem;
    padding: 5rem;
    background-color: #fff;
    box-shadow: 0 1.4rem 8rem rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    border-radius: .8rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
}

.blog-post img {
    width: 100%;
}

.blog-post__img {
    min-width: 35rem;
    max-width: 35rem;
    height: 30rem;
    transform: translateX(-8rem);
    position: relative;
}

.blog-post__img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    border-radius: .8rem;
}

.blog-post__img::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    /*background: linear-gradient(to right, rgba(79,172,254,.8), rgba(0,242,254,.8));*/
    box-shadow: .5rem .5rem 3rem 1px rgba(0,0,0,.05);
    border-radius: .8rem;
}

.blog-post__title {
    font-size: 2.5rem;
    color: #000;
    margin: 1.5rem 0 2rem;
}

.blog-post__text {
    /*margin-bottom: 3rem; */
    font-size: 14px;
    font-weight: normal;
    color: rgba(0,0,0,.7);
}

@media screen and (max-width: 1068px) {
    .blog-post {
        max-width: 60rem;
    }

    .blog-post__img {
        min-width: 30rem;
        max-width: 30rem;
        height: 18rem;
    }
}

@media screen and (max-width: 868px) {
    .blog-post {
        max-width: 50rem;
    }

    .blog-post__img {
        height: 18rem;
    }
}

@media screen and (max-width: 768px) {
    .blog-post {
        padding: 2.5rem;
        flex-direction: column;
    }

    .blog-post__img {
        /*min-width: 100%;
        max-width: 100%;*/
        min-width: 25rem;
        max-width: 25rem;
        transform: translate(0,-4rem);
        height: 18rem;
    }
}

.hide {
    display: none;
  }