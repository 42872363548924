.footer-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  display: block;
}

footer{
  position: relative;
  width: 100%;
  height: auto;
  padding: 50px 100px;
  background-color: transparent;
  color: #fff;
  justify-content: center;
}   
footer .container{
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
}
footer .container .line h2{
  position: relative;
  margin-bottom: 15px;
  font-weight: 700;
  color: #fff;
}
footer .container .line h2::before{
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 50px;
  height: 2px;
  background-color: #fff;
}
footer .container .line p{
  color: #fff;
}
.footer-1 .line p{
  text-align: justify;
  padding: 18px 40px 20px 10px;
}
.social{
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(3, 50px);
  grid-gap: 10px;
}
.social li{
  list-style: none;
}
.social li a{
  display: inline-block;
  width: 48px;
  height: 48px;
  background-color: #3586ff;
  display: grid;
  align-content: center;
  justify-content: center;
  text-decoration: none;
  border-radius: 50px;
}
.social li a .fa{
  color: #fff;
  font-size: 20px;
}
.social li a .fa:hover{
  color: #007b;
}
.footer-2{
  position: relative;
  z-index: 2;
}
.footer-2 li{
  list-style: none;
}
.footer-2 li a{
  color: #fff;
  text-decoration: none;
  margin-bottom: 10px;
  display: inline-block;
  font-size: 20px;            
}
.footer-2 li a:hover{
  color: #007bff;
}
.footer-3{
  position: relative;
  z-index: 2;
}
.footer-3 li{
  display: grid;
  grid-template-columns: 30px 1fr;
  margin-bottom: 10px;
}
.footer-3 li span{
  color: #fff;
  text-decoration: none;
}
.footer-3 a{
  color: #fff;
  text-decoration: none;
  z-index: 30;
}
.footer-3 a:hover{
  color: #007bff;
}
.copyright{
  width: 100%;
  background-color: #1167b1;
  padding: 8px 100px;
  text-align: center;
  color: #fff;
}
@media(max-width: 991px){
  footer{padding: 40px;
  }
  footer .container{
      grid-template-columns: repeat(2, 1fr);
  }
  .copyright{
      padding: 8px 40px;
  }
}
@media(max-width: 768px){
  footer .container{
      grid-template-columns: repeat(1, 1fr);
  }
}

.footer-1 {
  z-index: 2;
}

.footer-1 img {
  width: 260px;
  height: auto;
  border-radius: 5px;
}

footer .wave {
  position: absolute;
  top: -100px;
  left: 0;
  width: 100%;
  height: 100px;
  background: url('../../assets/images/wave.png');
  background-size: 1000px 100px;
}

footer .wave#wave1 {
  z-index: 1000;
  opacity: 1;
  bottom: 0;
  animation: animateWave 4s linear infinite;
}

footer .wave#wave2 {
  z-index: 999;
  opacity: 0.5;
  bottom: 10px;
  animation: animateWave_02 4s linear infinite;
}

footer .wave#wave3 {
  z-index: 1000;
  opacity: 0.2;
  bottom: 15px;
  animation: animateWave 3s linear infinite;
}

footer .wave#wave4 {
  z-index: 999;
  opacity: 0.7;
  bottom: 20px;
  animation: animateWave_02 3s linear infinite;
}

@keyframes animateWave {
  0% {
    background-position-x: 1000px;
  }
  100% {
    background-position-x: 0px;
  }
}

@keyframes animateWave_02 {
  0% {
    background-position-x: 0px;
  }
  100% {
    background-position-x: 1000px;
  }
}