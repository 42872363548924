.particle-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    background: url('../../assets/images/footer-bg.webp');

}

#tsparticles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    background: linear-gradient(to right, rgba(0,0,0,.5), rgba(0,0,0,.5)), url('../../assets/images/footer-bg.webp');

    z-index: 1;    
}