@import url(//fonts.googleapis.com/css?family=Montserrat:300,400,500);


.page-heading-black {
  position: relative;
  font-size:50px; 
  font-weight:700;  
  color: #fff;
  letter-spacing:1px; 
  text-transform:uppercase; 
  text-align:center; 
  margin:auto; 
  white-space:nowrap; 
  padding-bottom:13px;
  font-family: "Raleway", sans-serif;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.page-heading-black::before {
  background-color: #007bff;
  content: '';
  display: block;
  height: 3px;
  width: 75px;
  margin-bottom: 5px;
}
.page-heading-black::after {
  background-color: #007bff;
  content: '';
  display: block;
  position: absolute; 
  right:0; 
  bottom:0;
  height: 3px;
  width: 75px;
  margin-bottom: 0.25em;
  z-index: 5;
}

.contact-head {
    display: flex;
    height: 400px;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
    margin: 0;
    padding: 2rem;
    /*background: url('../../assets/images/img-home.jpg') center center/cover no-repeat;*/
    background: linear-gradient(rgba(0,0,0,.5), rgba(0,0,0,.5));

  }
.contact-head h1 {
    width: 340px;
    position: relative;
    width:100%;
    height: fit-content;
}

.wrapper {
  margin: 0;
  background: #fff;
  padding: 3rem 0rem 0.5rem 0rem;
}

.contact3 {
  font-family: "Montserrat", sans-serif;
  color: #8d97ad;
  font-weight: 300;
  padding: 1rem 4rem 2rem 4rem; 
  margin: 1rem 4rem 2rem 4rem;;
  
}

.contact3 h1,
.contact3 h2,
.contact3 h3,
.contact3 h4,
.contact3 h5,
.contact3 h6 {
  color: #3e4555;
}

.contact-box {
    border: 1px solid #007bff;
    padding: 2rem;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.contact3 .font-weight-medium {
  font-weight: 500;
}

.card-shadow {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    height: 100%;
    width: 100%;
  }

/*.contact3  {
  -webkit-box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.1);
  box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.1);
}*/

.contact3 .btn-danger-gradiant {
  background: #ff4d7e;
  background: -webkit-linear-gradient(legacy-direction(to right), #ff4d7e 0%, #ff6a5b 100%);
  background: -webkit-gradient(linear, left top, right top, from(#ff4d7e), to(#ff6a5b));
  background: -webkit-linear-gradient(left, #ff4d7e 0%, #ff6a5b 100%);
  background: -o-linear-gradient(left, #ff4d7e 0%, #ff6a5b 100%);
  background: linear-gradient(to right, #ff4d7e 0%, #ff6a5b 100%);
  width: 100%;
}

.contact3 .btn-danger-gradiant:hover {
  background: #ff6a5b;
  background: -webkit-linear-gradient(legacy-direction(to right), #ff6a5b 0%, #ff4d7e 100%);
  background: -webkit-gradient(linear, left top, right top, from(#ff6a5b), to(#ff4d7e));
  background: -webkit-linear-gradient(left, #ff6a5b 0%, #ff4d7e 100%);
  background: -o-linear-gradient(left, #ff6a5b 0%, #ff4d7e 100%);
  background: linear-gradient(to right, #ff6a5b 0%, #ff4d7e 100%);
  cursor: pointer;
}

.address, .phone, .email {
    padding: 15px;
}

.address-section img {
    width: 50px;
    height: auto;
}



@media screen and (max-width: 960px){
    .contact3 {
        padding: 1rem 2rem 2rem 2rem;
        margin: 1rem 2rem 2rem 2rem;
      }

      .card-shadow { 
        margin-top: 1rem;
      }

}
@media screen and (max-width: 768px){
    .contact3 {
        padding: 1rem 2rem 2rem 2rem;
        margin: 1rem 2rem 2rem 2rem;
      }

      .card-shadow { 
        margin-top: 1rem;
      }
}


/* map */
.mapouter {
  position:relative;
  text-align:right;
  width:100%;
  height:100%;
}
.gmap_canvas {
  overflow:hidden;
  background:none!important;
  width:100%;
  height:100%;
}
.gmap_iframe {
  height:100%!important;
}