.services-section {
    padding: 3rem;
    background: #007bff;
  }

  .service-link {
      color: inherit;
      text-decoration: none;
  }
  
  /*h2 {
    text-align: center;
    color: #fff;
    margin: 20px;
  }*/

  h2 {
    position: relative;
    padding: 0;
    margin: 0;
    font-family: "Raleway", sans-serif;
    font-weight: 300;
    font-size: 40px;
    color: #080808;
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
  }
  
  h2 span {
    display: block;
    font-size: 0.5em;
    line-height: 1.3;
  }
  h1 em {
    font-style: normal;
    font-weight: 600;
  }

  .twelve h2 {
    font-size:26px; 
    font-weight:700;  
    color: #fff;
    letter-spacing:1px; 
    text-transform:uppercase; 
    width:220px; 
    text-align:center; 
    margin:auto; 
    white-space:nowrap; 
    padding-bottom:13px;
  }
  .twelve h2:before {
      background-color: #fff;
      content: '';
      display: block;
      height: 3px;
      width: 75px;
      margin-bottom: 5px;
  }
  .twelve h2:after {
      background-color: #fff;
      content: '';
      display: block;
      position:absolute; 
      right:0; 
      bottom:0;
      height: 3px;
      width: 75px;
      margin-bottom: 0.25em;
      z-index: 5;
  }

  
  .main {
      width: 100%;
      min-height: 400px;
      text-align: center;
      position: relative;
      cursor: pointer;
      margin-top: 7rem;
  }

  .service {
      background: #fff;
      padding: 30px;
      border-radius: 10px;
      position: absolute;
      bottom: 0;
      z-index: 2;
      box-shadow: 0 0 20px -15px #030380;
      transition: transform 0.8s;
      min-height: 400px;
  }

  .service-logo {
      width: 130px;
      height: 130px;
      border-radius: 50%;
      margin: -90px auto 0;
      background: #fff;
      border: 18px solid #007bff;
  }

  .service-logo img {
      width: 50px;
      margin: 20px;
      transition: transform 1s;
  }

  .service h4 {
      height: 35px;
      width: 80%;
      background: #fff;
      margin: 50px auto;
      position: relative;
  }

.service h4::after{
    content: '';
    height: 30px;
    width: 40px;
    background: linear-gradient(to right, #fff, #007bff);
    position: absolute;
    right: -5px;
    top: -5px;
    z-index: -1;
}

.service h4::before{
    content: '';
    height: 30px;
    width: 40px;
    background: linear-gradient(to right,#007bff, #fff);
    position: absolute;
    left: -5px;
    bottom: -5px;
    z-index: -1;
}

.shadowOne{
    height: 200px;
    width: 80%;
    background: #fff;
    position: absolute;
    border-radius: 10px;
    bottom: 0;
    left: 10%;
    z-index: 1;
    box-shadow: 0 0 20px -15px #030380;
    transition: transform 0.8s;
}

.shadowTwo{
    height: 200px;
    width: 64%;
    background: #fff;
    position: absolute;
    border-radius: 10px;
    bottom: 0;
    left: 18%;
    z-index: 0;
}

.main:hover .service{
    transform: translateY(-50px);
}

.main:hover .shadowOne{
    transform: translateY(-25px);
}

.main:hover img{
    transform: rotate(360deg);
}

@media screen and (max-width: 960px) {
    .main {
        margin-top: 10rem;
    }
}

@media screen and (max-width: 768px) {
    .main {
        margin-top: 10rem;
    }
}